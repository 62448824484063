var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { persistent: _vm.deleting, "max-width": "700" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on: dialog }) {
            return [
              _c(
                "v-tooltip",
                {
                  attrs: { left: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on: tooltip }) {
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  attrs: {
                                    disabled: _vm.disabledButton,
                                    small: "",
                                    color: "error",
                                    icon: "",
                                  },
                                },
                                { ...tooltip, ...dialog }
                              ),
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("delete"),
                                ]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                },
                [_c("span", [_vm._v("Delete this instance")])]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.deleteInstanceDialog,
        callback: function ($$v) {
          _vm.deleteInstanceDialog = $$v
        },
        expression: "deleteInstanceDialog",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c("v-card-title", [
            _c(
              "div",
              { staticClass: "d-flex align-center secondary--text" },
              [
                _c("v-icon", { staticClass: "mr-1" }, [_vm._v("delete")]),
                _c("span", { staticClass: "text-uppercase" }, [
                  _vm._v("instance deletion"),
                ]),
              ],
              1
            ),
          ]),
          _c("v-spacer"),
          _c("v-divider"),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "", align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [_c("v-subheader", [_vm._v("INSTANCE NAME")])],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "subtitle-2", attrs: { cols: "8" } },
                    [_vm._v(_vm._s(_vm.instanceName))]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "", align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [_c("v-subheader", [_vm._v("INSTANCE DESCRIPTION")])],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "subtitle-2", attrs: { cols: "8" } },
                    [_vm._v(_vm._s(_vm.instanceDescription))]
                  ),
                ],
                1
              ),
              _c(
                "v-alert",
                {
                  staticClass: "mt-5",
                  attrs: {
                    outlined: "",
                    color: "error",
                    prominent: "",
                    type: "warning",
                  },
                },
                [
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v("Here is what happens when you delete an instance"),
                  ]),
                  _c("ul", { staticClass: "mt-3" }, [
                    _c("li", [
                      _c("span", { staticClass: "subtitle-2" }, [
                        _vm._v(
                          " The contents (files, tables, and application) of all states in the instance will be deleted permanently. "
                        ),
                      ]),
                    ]),
                    _c("li", { staticClass: "subtitle-2" }, [
                      _vm._v(
                        "All members of this instance will lose their access and data."
                      ),
                    ]),
                  ]),
                  _c("v-divider", {
                    staticClass: "my-4 secondary",
                    staticStyle: { opacity: "0.22" },
                  }),
                  _c("v-checkbox", {
                    staticClass: "secondary--text",
                    attrs: { color: "error", label: "I understand" },
                    model: {
                      value: _vm.agreeBox,
                      callback: function ($$v) {
                        _vm.agreeBox = $$v
                      },
                      expression: "agreeBox",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c("div", { staticClass: "d-flex flex-column" }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-center justify-end" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          disabled: _vm.deleting,
                          color: "secondary",
                          text: "",
                        },
                        on: {
                          click: function ($event) {
                            _vm.deleteInstanceDialog = false
                          },
                        },
                      },
                      [_vm._v("cancel")]
                    ),
                    _c("DeleteButton", {
                      attrs: {
                        buttonName: "delete",
                        objectType: _vm.nuvolosObjectTypes.INSTANCE,
                        objectName: _vm.instanceName,
                        buttonInsideDialog: true,
                        apiURL: "/instances/" + _vm.instanceId,
                        id: parseInt(_vm.instanceId, 10),
                        higherLevelId: parseInt(_vm.$route.params.sid, 10),
                        warningText:
                          "You are about to delete this instance with all its contents permanently. This means that all your data and the snapshots related to this instance will be removed.",
                        disabled: !_vm.agreeBox,
                        fetchString: "spaceStore/fetchSpaceInstances",
                        routeAfter:
                          _vm.instanceId.toString() ===
                          _vm.$route.params.iid.toString()
                            ? {
                                name: "snapshot-overview",
                                params: {
                                  oid: _vm.$route.params.oid,
                                  sid: _vm.$route.params.sid,
                                  iid: _vm.masterInstanceId,
                                  snid: _vm.masterDevelopmentSnapshotId,
                                },
                              }
                            : null,
                        isAsyncDeletion: true,
                      },
                      on: {
                        error: function ($event) {
                          return _vm.errorMessage($event.error)
                        },
                        deleting: function ($event) {
                          return _vm.setDeletingStatus($event.value)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _vm.error
                      ? _c(
                          "v-alert",
                          {
                            staticClass: "mt-4",
                            attrs: {
                              color: "error",
                              icon: "warning",
                              outlined: "",
                            },
                          },
                          [
                            _c("div", { staticClass: "d-flex flex-column" }, [
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v(_vm._s(_vm.errorContent)),
                              ]),
                              _c(
                                "span",
                                [
                                  _vm._v(
                                    " For more information on instance deletion issues, check the troubleshooting documentation "
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "font-weight-bold",
                                      attrs: {
                                        small: "",
                                        text: "",
                                        color: "error",
                                        href: "https://docs.nuvolos.cloud/faqs/troubleshooting/administration-troubleshooting/i-cant-delete-an-instance",
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v(" here ")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }